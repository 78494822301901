import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>Welcome to Shaily Sharma's Homepage</h1>
        <p>
          Visit my portfolio at{' '}
          <a href="https://portfolio.shaily.dev" target="_blank" rel="noopener noreferrer">
            portfolio.shaily.dev
          </a>
        </p>
        <section>
          <h2>SSH Public Key</h2>
          <pre>
{`ssh-rsa AAAAB3NzaC1yc2EAAAADAQABAAACAQC3psDAKRNW7c5D1+pW3d+vr5+OQBnWrZWBBFslYqxTYLyagSAIH294XPkxZClSjC40e60nhwXKuTIIyguFta9X0EWSf0HXlDAxnyf3EKFVFjsgQ/1nFvkZkRGFs1ux2dP+oDlpMpWrhTmAdrVc+vAkjG6EMkHF5pLtU2KsxlmqWjU5s+IG7rAV4pHxCeJFhyKm35tY3wZirbUR/IjO/XXFkI7RIJQbjW5E607Jwpfj7juKs3FMen97RMBWD0q8pMmWFMl4rVfTyLzK7WePybDDNlrP++TiOkRadEQ1rB+Kmf2ep2gaDO7XfSQ/0H1ypLSa8XlOLGYoLp3zvpsHDnbb1V0TgUZbv6zPcm316tQq0hzSmahzNMvDlEAalyoHvmMaIRkmRS//Rde3XfoTCML4cx3vHdPuQ7cAWQqqvcg/9rFqIhcPFrhl4gvanrSRNg+5n5TzEDVr0NsGbSkX/Ivwl3ZClsjU8jdaPZz9ei62xdPQ8sXKXhvk7HyevvN2Mx+xGbEvJgpzg7WKp4haWFxwU6BgYoqYrztJmUDL6pudTnYBFMMa5o5I1aHeww8gcZOFQEPuCylbI4RRwAf9vdlxqAhTH1e/4AqNqQYQCZCHTAeKl8iGefFjfnFQ3d5j3SW26tdTA9c7q3Xg/vD+OlCshcGhy5xprk6cIM7QPBTS7w== shailysharmawork@gmail.com
`}
          </pre>
        </section>
      </header>
    </div>
  );
}

export default App;